<template>
  <nav class="navbar navbar-light bg-light">
    <div
      class="modal fade"
      id="modalUserInfo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUserInfoLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-3 radius-1r">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalUserInfoLabel">{{$t('mdt_room.lbl_profile')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
              @click="showChangeInfo(false)"
            >
              <span aria-hidden="true" class="fs-18 fw-500">&times;</span>
            </button>
          </div>
          <div class="mb-3">
            <p class="fs-16 fw-600 txt-blur-gray mb-2">
              {{$t('mdt_room.lbl_fullname')}}
              <span class="text-danger">*</span>
            </p>
            <input
              type="text"
              v-model="form.name"
              class="border radius-1r form-control bg-white py-2"
            />
          </div>
          <div class="mb-3" v-if="has_password">
            <p class="fs-16 fw-600 txt-blur-gray mb-2">
              {{$t('mdt_room.lbl_old_pwd')}}
              <span class="text-danger" v-if="!has_password">*</span>
            </p>
            <input
              type="password"
              v-model="form.old_password"
              class="border radius-1r form-control bg-white py-2"
              autocomplete="off"
            />
          </div>
          <div class="mb-3">
            <p class="fs-16 fw-600 txt-blur-gray mb-2">
              {{$t('mdt_room.lbl_new_pwd')}}
              <span class="text-danger" v-if="!has_password">*</span>
            </p>
            <input
              type="password"
              v-model="form.password"
              class="border radius-1r form-control bg-white py-2"
              autocomplete="off"
            />
          </div>
          <div class="mb-3">
            <button
              :disabled="
                !form.name ||
                ((!form.password || !form.password) && has_password)
              "
              class="btn-action btn btn-primary bg-pri bd-pri w-100"
              @click="changeInfo()"
            >{{$t('mdt_room.btn_save')}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <a class="navbar-brand d-flex align-items-center" href="/">
        <svg
          width="32"
          height="32"
          viewBox="0 0 514 514"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="me-2"
        >
          <path
            d="M158.973 472.85L40.4355 354.313C-13.4785 300.399 -13.4785 212.939 40.4355 158.973L158.973 40.4355C212.887 -13.4785 300.346 -13.4785 354.313 40.4355L472.85 158.973C526.764 212.887 526.764 300.346 472.85 354.313L354.313 472.85C300.346 526.764 212.939 526.764 158.973 472.85Z"
            fill="#476DD6"
          />
          <path
            d="M259.662 160.601C264.334 160.181 268.797 159.341 272.891 158.028C280.188 156.034 286.593 152.464 291.842 147.739C292.577 147.109 293.312 146.374 294.047 145.692L294.31 145.429C303.969 135.77 309.271 122.121 308.484 106.372C307.907 94.5076 303.602 83.9558 296.725 75.7664C294.835 73.4565 292.735 71.3566 290.425 69.4667C282.183 62.5371 271.631 58.2849 259.767 57.655C244.753 56.92 231.786 61.6447 222.232 70.4116C221.707 70.8841 221.235 71.3041 220.762 71.7766C214.725 77.6037 210.263 85.0582 207.953 93.7727C207.9 94.0352 207.795 94.2976 207.743 94.5601C206.693 98.2873 205.958 102.277 205.591 106.424C205.591 106.949 205.486 107.474 205.433 107.947C199.816 167.793 169.105 198.976 109.784 205.118C108.997 205.223 108.209 205.275 107.422 205.38C77.2888 208.11 57.6025 228.531 57.76 256.932C57.865 271.631 63.4296 284.23 72.984 293.312C82.0659 302.867 94.6651 308.379 109.312 308.484C137.712 308.694 158.134 289.008 160.863 258.822C160.916 257.982 161.021 257.247 161.126 256.46C167.215 197.086 198.451 166.375 258.297 160.758C258.665 160.706 259.19 160.653 259.662 160.601Z"
            fill="white"
          />
          <path
            d="M406.81 307.749C411.482 307.329 415.944 306.489 420.039 305.177C427.336 303.182 433.74 299.612 438.99 294.887C439.725 294.257 440.46 293.522 441.195 292.84L441.457 292.577C451.117 282.918 456.419 269.269 455.632 253.52C455.054 241.656 450.749 231.104 443.872 222.915C441.982 220.605 439.883 218.505 437.573 216.615C429.331 209.685 418.779 205.433 406.915 204.803C391.901 204.068 378.934 208.793 369.38 217.56C368.855 218.032 368.382 218.452 367.91 218.925C361.873 224.752 357.41 232.206 355.101 240.921C355.048 241.183 354.943 241.446 354.891 241.708C353.841 245.436 353.106 249.425 352.738 253.573C352.738 254.098 352.633 254.622 352.581 255.095C346.964 314.941 316.253 346.124 256.932 352.266C256.144 352.371 255.357 352.424 254.569 352.529C224.436 355.258 204.75 375.68 204.908 404.08C205.013 418.779 210.577 431.379 220.132 440.46C229.214 450.015 241.813 455.527 256.459 455.632C284.86 455.842 305.281 436.156 308.011 405.97C308.064 405.13 308.168 404.395 308.273 403.608C314.363 344.234 345.599 313.524 405.445 307.907C405.812 307.854 406.285 307.801 406.81 307.749Z"
            fill="white"
          />
          <path
            d="M256.828 308.431C285.531 308.431 308.799 285.163 308.799 256.46C308.799 227.757 285.531 204.488 256.828 204.488C228.124 204.488 204.856 227.757 204.856 256.46C204.856 285.163 228.124 308.431 256.828 308.431Z"
            fill="white"
          />
        </svg>
        <span class="fs-24 fw-700 txt-pri">HODO</span>
      </a>
      <div class="d-flex align-items-center">
        <div class="dropdown">
          <a
            class="btn dropdown-toggle border-0 robo-16-500 d-language"
            type="button"
            id="dropdownMenuLang"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../assets/images/language/Vietnam.png"
              alt
              width="20"
              height="16"
              class="cursor-pointer me-2"
              v-if="$lang == 'vi'"
            />
            <img
              src="../../assets/images/language/UK.png"
              alt
              width="20"
              height="16"
              class="cursor-pointer me-2"
              v-if="$lang == 'en'"
            />
            {{ $lang == "vi" ? "Tiếng việt" : $lang == "en" ? "English" : "" }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLang">
            <li>
              <a
                @click="changeLanguage('vi')"
                class="dropdown-item d-flex fs-16 align-items-center"
                href="javascript:;"
              >
                <img
                  src="../../assets/images/language/Vietnam.png"
                  alt
                  width="20"
                  height="16"
                  class="cursor-pointer me-2"
                />Tiếng việt
              </a>
            </li>
            <li>
              <a
                @click="changeLanguage('en')"
                class="dropdown-item d-flex fs-16 align-items-center"
                href="javascript:;"
              >
                <img
                  src="../../assets/images/language/UK.png"
                  alt
                  width="20"
                  height="16"
                  class="cursor-pointer me-2"
                />English
              </a>
            </li>
          </ul>
        </div>
        <div class="d-flex align-items-center" v-if="!!appUtils.getLocalToken()">
          <p class="fw-20 fw-500 text-black-50 mb-0 me-2">{{ getCurrentDateTime() }}</p>
          <div class="dropdown">
            <button
              class="btn bg-white text-black border dropdown-toggle"
              type="button"
              id="dropdownMenuUserInfo"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >{{$t('mdt_room.lbl_account')}}</button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuUserInfo">
              <li>
                <a class="dropdown-item" href="javascript:;" @click="showChangeInfo(true)">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="me-2"
                  >
                    <path
                      d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
                      fill="#1D2939"
                    />
                  </svg>
                  {{$t('mdt_room.lbl_profile')}}
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:;" @click="onLogout()">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="me-2"
                  >
                    <path
                      d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
                      fill="#1D2939"
                    />
                  </svg>
                  {{$t('mdt_room.lbl_logout')}}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex align-items-center" v-if="!!!appUtils.getLocalToken() && !isLoginPage">
          <a
            href="javascript:;"
            class="text-dark text-decoration-none fw-500"
            @click="onLogin()"
          >{{$t('log_in.lbl_login')}}</a>
          <!-- <button
              class="btn btn-secondary me-2"
              @click="onLogin()"
            >
              Tham gia cuộc họp
            </button>
            <button
              class="btn btn-primary bg-pri bd-pri"
              @click="onLogin()"
            >
              Bắt đầu cuộc họp
          </button>-->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'Navbar',
  data () {
    return {
      appUtils,
      form: {
        name: '',
        password: '',
        old_password: ''
      },
      has_password: false
    }
  },
  computed: {
    isLoginPage () {
      return this.$route.name === 'Login'
    }
  },
  mounted () {
    if (this.$user) {
      this.getMe()
      this.checkHasPw()
    }
  },
  methods: {
    getCurrentDateTime () {
      return this.moment()
        .locale(this.$lang)
        .format('HH:mm - dd, DD MMM')
    },
    onLogin () {
      this.$router.push({ name: 'Login' })
    },
    onLogout () {
      var r = confirm(this.$t('mdt_room.lbl_exit_confirm_msg'))
      if (!r) {
        return false
      }
      appUtils.removeLocalToken()
      appUtils.removeLocalUser()
      appUtils.removeClinicId()
      appUtils.removeDoctorClinic()
      appUtils.removeFirebaseAccessToken()
      // Redirect to login page.
      setTimeout(() => {
        window.location.href = '/'
      }, 300)
    },
    showChangeInfo (show) {
      window.$('#modalUserInfo').modal(show ? 'show' : 'hide')
    },
    async checkHasPw () {
      const self = this
      await this.$rf
        .getRequest('AuthRequest')
        .checkHasPw()
        .then(r => {
          self.has_password = r.data
        })
    },
    async changeInfo () {
      const self = this
      const params = {}
      if (this.form.name) params.name = this.form.name
      if (this.form.old_password) params.old_password = this.form.old_password
      if (this.form.password) params.password = this.form.password
      await this.$rf
        .getRequest('AuthRequest')
        .updateInfo(params)
        .then(r => {
          appUtils.setLocalUser(r.data)
          self.$toast.open({
            message: this.$t('mdt_room.lbl_success_msg'),
            type: 'success'
          })
          self.showChangeInfo()
        })
    },
    async getMe () {
      const resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        this.form.name = resp.data.name
        appUtils.setLocalUser(resp.data)
        const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
        this.$lang = lang
        appUtils.setLocalLang(lang)
      }
    },
    changeLanguage (l) {
      const codeList = ['en', 'vi']
      const lang = codeList.find(item => item === l) ? l : 'vi'
      this.$lang = lang
      const langId = appUtils.getLangId(lang)
      if (langId) this.saveLang(langId)
    },
    async saveLang (id) {
      if (!this.$user) return
      await this.$rf
        .getRequest('AuthRequest')
        .changeLang({ lang: id })
        .then(() => {
          this.$toast.open({
            message: this.$t('mdt_room.lbl_success_msg'),
            type: 'success'
          })
          this.getMe()
        })
    }
  }
}
</script>

<style scoped></style>
